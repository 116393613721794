<template>
  <div class="mi-toast" v-show="visible" @touch.stop.prevent>
    <transition name="fade">
      <div class="mi-toast-main" v-if="visible">
        {{ message }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: '',
      visible: false
    }
  }
}
</script>

<style lang="scss" scoped>
.mi-toast {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  &-main {
    max-width: 60%;
    padding: 36px 45px;
    background-color: #d4ecf7;
    border: 1px solid #6ea9e5;
    @include round(40px);
    color: $title-color-text;
    position: fixed;
    left: 50%;
    bottom: 150px;
    transform: translateX(-50%);
    z-index: 10;
    text-align: center;
  }
}

.fade-enter-active {
  transition: all 300ms ease-in;
}
.fade-leave-active {
  transition: all 250ms ease-in;
}
.fade-enter,
.fade-leave-to {
  bottom: 0;
  opacity: 0;
}
</style>
