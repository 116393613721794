export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: '米币中心'
    },
    component: () =>
      import(
        /* webpackChunkName: "mibi-login-money" */ '@/views/login/index.vue'
      )
  }
]
