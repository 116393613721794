<template>
  <input
    class="mi-input"
    :type="type"
    :placeholder="placeholder"
    v-model="val"
    @focus="focusHandle"
    @blur="blurHandle"
  />
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String
    },
    value: {
      type: [String, Number]
    }
  },
  data() {
    return {
      val: this.value,
      focused: false
    }
  },
  methods: {
    focusHandle() {
      this.$emit('focus')
      this.focused = true
    },
    blurHandle() {
      const top =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop

      window.scrollTo(0, top + 1)
      window.scrollTo(0, top)
      this.$emit('blur')
      this.focused = false
    }
  },
  watch: {
    val(newVal) {
      this.$emit('input', newVal)
    },
    value(val) {
      this.val = val
    }
  }
}
</script>

<style lang="scss" scoped>
.mi-input {
  width: 100%;
  height: 160px;
  padding: 47px 50px;
  background: #f7f7f7;
  @include round(40px);
  color: $theme-text-color;
  font-weight: 600;
  font-size: 56px;
  font-family: Mitype;
}
</style>
