import MiMessage from './messageBox.vue'

export default function(Vue) {
  const Constructor = Vue.extend(MiMessage)
  let instance
  let promise

  const func = (options = {}) => {
    if (!instance) {
      instance = new Constructor().$mount()
      document.body.appendChild(instance.$el)
    }
    const callback = action => {
      if (action === 'confirm') {
        promise.resolve('confirm')
      } else {
        promise.reject('cancel')
      }
      instance.visible = false
    }

    Constructor.prototype.promiseHandler = callback

    Object.assign(instance, options, { visible: true })

    window.addEventListener('popstate', () => {
      instance.visible = false
    })

    return new Promise((resolve, reject) => {
      promise = {
        resolve,
        reject
      }
    })
  }

  Vue.prototype.$messageBox = Vue.messageBox = func
}
