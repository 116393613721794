import {
  RECHARGE_GTE_CHARGE_TYPE_INFO,
  RECHARGE_GET_AMOUNT_LIST,
  RECHARGE_UPDATE_AMOUNT_LIST,
  RECHARGE_GET_PHONE_INFO,
  RECHARGE_UPDATE_ID
} from '../mutations'
import http from '@/api'
import { carrier, payNameConfig } from '@/config'
import { locale } from '@/utils'

const payName = payNameConfig[locale]
const chargeHandler = (list, marketingDescription) =>
  list.map(id => ({
    id,
    name: payName[id] || id,
    desc: marketingDescription[id] || ''
  }))

export default {
  state: {
    id: '',
    rechargeAmountList: [],
    rechargePhoneInfo: [],
    rechargeTypeInfo: {}
  },
  mutations: {
    [RECHARGE_UPDATE_ID](state, payload) {
      state.id = payload
    },
    [RECHARGE_GTE_CHARGE_TYPE_INFO](state, payload) {
      state.rechargeTypeInfo = payload
    },
    [RECHARGE_GET_AMOUNT_LIST](state, payload) {
      state.rechargeAmountList = payload
    },
    [RECHARGE_UPDATE_AMOUNT_LIST](state, index) {
      const { rechargeAmountList } = state
      const tempList = rechargeAmountList.map(({ id }) => ({
        id,
        select: false
      }))

      if (index >= 0) {
        const { select, id } = rechargeAmountList[index]

        tempList.splice(index, 1, {
          id,
          select: !select
        })
      }
      state.rechargeAmountList = tempList
    },
    [RECHARGE_GET_PHONE_INFO](state, payload) {
      state.rechargePhoneInfo = payload
    }
  },
  actions: {
    async rechargeGetTypes({ commit }, payload = {}) {
      const {
        chargeTypes = {},
        fixedRechargeAmount,
        marketingDescription,
        phoneCardValues
      } = await http.getChargeType(payload)

      const rechargeAmountList = fixedRechargeAmount.split(':').map(v => ({
        select: false,
        id: +v
      }))
      rechargeAmountList[0].select = true

      const rechargePhoneInfo = Object.entries(phoneCardValues).map(
        ([id, str]) => {
          return {
            id,
            name: carrier[id],
            counts: str.split(':').map(v => +v)
          }
        }
      )
      const { up, down } = chargeTypes
      const rechargeTypeInfo = {
        up: chargeHandler(up, marketingDescription),
        down: chargeHandler(down, marketingDescription)
      }

      commit(RECHARGE_UPDATE_ID, rechargeAmountList[0].id)
      commit(RECHARGE_GET_AMOUNT_LIST, rechargeAmountList)
      commit(RECHARGE_GET_PHONE_INFO, rechargePhoneInfo)
      commit(RECHARGE_GTE_CHARGE_TYPE_INFO, rechargeTypeInfo)
    }
  }
}
