/**
 * 用户同意/不同意隐私授权（海外服务）
 * 路由对应的entry
 * @author cuiliangliang
 */

export default {
  '/': 'HOMEPAGE',
  '/payment': 'PAYMENTPAGE',
  '/recharge': 'RECHARGEPAGE',
  '/record/recharge': 'TRADE-RECORDPAGE',
  '/record/consume': 'RECHARGE-RECORDPAGE',
  '/record/giftcard': 'GIFTCARD-RECORDPAGE'
}
