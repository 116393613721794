<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { locale } from '@/utils'

export default {
  created() {
    this.setLocale()
  },
  methods: {
    setLocale() {
      this.$i18n.locale = locale
      sessionStorage.setItem('localKey', locale)
    }
  },
  mounted() {
    window.onBackPressed = function() {
      return false
    }
    if (MiFiHybrid.isApp()) {
      MiFiHybrid.invoke('UI', 'setMenu', null)
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  max-width: 1080px;
  height: 100%;
  margin: 0 auto;
}
</style>
