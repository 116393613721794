export default [
  {
    path: '/recharge',
    name: 'recharge',
    meta: {
      title: '米币收银台'
    },
    component: () =>
      import(
        /* webpackChunkName: "recharge-result" */ '@/views/recharge/index.vue'
      )
  },
  {
    path: '/mibi',
    name: 'mibi',
    component: () =>
      import(
        /* webpackChunkName: "mibi-login-money" */ '@/views/recharge/mibi.vue'
      )
  },
  {
    path: '/phone',
    name: 'phone',
    component: () =>
      import(/* webpackChunkName: "phone" */ '@/views/recharge/phone.vue')
  },
  {
    path: '/phone-next',
    name: 'phoneNext',
    component: () =>
      import(/* webpackChunkName: "phone" */ '@/views/recharge/phone-next.vue')
  },
  {
    path: '/money',
    name: 'money',
    component: () =>
      import(
        /* webpackChunkName: "mibi-login-money" */ '@/views/recharge/money.vue'
      )
  },
  {
    path: '/recharge-result',
    name: 'rechargeResult',
    component: () =>
      import(
        /* webpackChunkName: "recharge-result" */ '@/views/recharge/result.vue'
      )
  }
]
