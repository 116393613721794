<template>
  <div class="mi-loading" @click.prevent.stop v-show="visible">
    <img class="img" src="./images/loading.gif" alt="loading" />
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  }
}
</script>

<style lang="scss" scoped>
.mi-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  .img {
    width: 144px;
    height: 144px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
