<template>
  <div :class="footerClass">
    <mi-button :disabled="disabled" @click="$emit('click')">
      {{ content }}
    </mi-button>
  </div>
</template>

<script>
import MiButton from '../mi-button'

export default {
  components: {
    MiButton
  },
  props: {
    fixed: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    }
  },
  computed: {
    footerClass() {
      return this.fixed ? 'fixed' : 'margin'
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed {
  position: fixed;
  left: 40px;
  right: 40px;
  bottom: 0;
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 90px;
  background-color: #fff;
}
.margin {
  margin-top: 90px;
}
</style>
