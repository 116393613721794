/**
 * error
 * @author cuiliangliang
 */

export default [
  {
    path: '/error',
    name: 'error',
    component: () =>
      import(/* webpackChunkName: 'error' */ '@/views/error/index.vue')
  }
]
