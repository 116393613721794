<template>
  <div class="mi-title">
    <h1 class="title">{{ title }}</h1>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.mi-title {
  padding: 8px 74px 0;
  font-weight: 300;
  font-size: 88px;
  color: $title-color-text;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    flex: 1;
    margin-right: 40px;
  }
}
</style>
