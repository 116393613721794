import Vue from 'vue'
import VueRouter from 'vue-router'

import about from './route.about'
import login from './route.login'
import payment from './route.payment'
import recharge from './route.recharge'
import error from './route.error'

import VConsole from 'vconsole'

Vue.use(VueRouter)

// fix history 模式下跳转相同路由时报错
const originPush = VueRouter.prototype.push

VueRouter.prototype.push = function(location) {
  originPush.call(this, location).catch(err => err)
}

const routes = [...about, ...login, ...payment, ...recharge, ...error]
const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const {
    query: { vconsole },
    meta: { title = '米币' }
  } = to
  document.title = title

  if (process.env.NODE_ENV !== 'production' && vconsole) {
    new VConsole()
  }

  next()
})

export default router
