/**
 * @file options
 * @author cuiliangliang
 */

import axios from 'axios'
import cookie from 'js-cookie'
import router from '@/router'
import * as rax from 'retry-axios'

const { origin, href } = window.location
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_PREFIX,
  timeout: 30000,
  withCredentials: true
})
const serviceToken = cookie.get('serviceToken')
const referUrl = `${origin}/login?referer=${encodeURIComponent(href)}`

instance.defaults.raxConfig = {
  instance,
  retry: 3,
  retryDelay: 50,
  httpMethodsToRetry: ['GET', 'HEAD', 'OPTIONS']
}
rax.attach(instance)

instance.interceptors.request.use(
  config => {
    const { method, data } = config

    if (method === 'post') {
      const formData = new FormData()

      Object.entries(data).forEach(([key, value]) => {
        formData.append(key, value)
      })
      formData.append('serviceToken', serviceToken)
      config.data = formData
    }

    window.$indicator.show()
    return config
  },
  error => Promise.reject(error)
)

instance.interceptors.response.use(
  response => {
    if (router.currentRoute.query.status != 'waitPay') {
      window.$indicator.hide()
    }
    const { status, data } = response
    const {
      currentRoute: { path }
    } = router
    if (status === 200 || status === 304) {
      const { errCode, redirectUrl, returnUrl, errDesc } = data

      switch (errCode) {
        case 200:
        case 9600:
          return data
        case 1021:
          if (path === '/') {
            router.push('/login')
          } else {
            window.location.href = referUrl
          }
          return
        case 1022:
          router.push('/')
          return
        case 1023:
          window.location.href = redirectUrl
          return
        case 1007:
        case 1999:
        default:
          break
      }

      if (returnUrl) {
        if (returnUrl.indexOf('payment-result')) {
          return data
        }
        window.location.replace(returnUrl)
      } else {
        window.$$toast(errDesc || '请求异常~')
      }
    }
  },
  error => {
    window.$indicator.hide()
    return Promise.reject(error)
  }
)

/**
 *
 * @param {String} url 请求URL
 * @param {Object} params 请求参数
 * @returns
 */
export const get = (url, params) => {
  return instance.get(url, { params })
}

export const post = (url, data) => {
  return instance.post(url, data)
}
