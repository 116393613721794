import Vue from 'vue'
import Vuex from 'vuex'

import about from './module/about'
import recharge from './module/recharge'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    about,
    recharge
  }
})
