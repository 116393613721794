/**
 * 单位格式化
 * @author cuiliangliang
 */

import { div } from '@lzwme/asmd-calc'

export default price => {
  if (typeof price === 'number') {
    return div(price / 100)
  }
  return price
}
