/**
 * 检查海外用户隐私协议
 * @author cuiliangliang
 */

import { pathEntry } from '@/config'

export default {
  methods: {
    async checkPrivacy() {
      const { host } = window.location
      const zhCN =
        host === 'm.staging.mibi.n.xiaomi.com' || host === 'm.mibi.mi.com'

      if (!zhCN) {
        const {
          userRevoke,
          needPrivacyAuthorize
        } = await this.$http.checkPrivacy()

        if (needPrivacyAuthorize) {
          let agree = true

          try {
            const { userId } = this.$store.state.about.userInfo

            await this.$messageBox({
              content: this.$tc('message.checkPrivacy.content', userId),
              confirm: this.$t('message.checkPrivacy.confirm'),
              cancel: this.$t('message.checkPrivacy.cancel')
            })
          } catch {
            agree = false
            setTimeout(() => {
              this.$router.push('/error')
            }, 300)
          }

          await this.$http.privacyAuthorize({
            agree,
            market: this.market || 105,
            entry: pathEntry[this.$route.path]
          })
        } else if (userRevoke) {
          await this.$messageBox({
            content: this.$t('message.notService.content'),
            confirm: this.$t('message.notService.confirm')
          })
        }
      }
    }
  }
}
