<template>
  <div class="about">
    <div class="body">
      <mi-title :title="userInfo.nickName">
        <div class="icon-desc">
          <em class="icon"></em>
          {{ $t('docTitle.aboutTitle') }}
        </div>
      </mi-title>
      <div class="about-amount">
        <div class="about-amount-main">
          <h1 class="title">{{ $t('about.balance') }}</h1>
          <p class="amount">
            {{ userInfo.balance }}
            <span class="contain" v-if="showCard"
              >(含{{ userInfo.giftcardAvailableValue }}米币通用礼券)</span
            >
          </p>
          <router-link to="/recharge">
            <div class="recharge">
              {{ $t('about.recharge') }}
            </div>
          </router-link>
        </div>
      </div>
      <ul class="about-list">
        <li>
          <router-link to="/record/recharge">
            {{ $t('about.rechargeRecord') }}
            <em class="icon"></em
          ></router-link>
        </li>
        <li>
          <router-link to="/record/consume">
            {{ $t('about.consumeRecord') }}
            <em class="icon"></em>
          </router-link>
        </li>
        <li>
          <router-link to="/record/giftcard">
            {{ $t('about.gift') }}
            <p class="right">
              <span v-if="showCard">
                {{ userInfo.giftcardAvailableNumber }}张可用
              </span>
              <em class="icon"></em>
            </p>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="footer">
      <router-link to="/recharge">
        <mi-button>{{ $t('common.rechargeBtn') }}</mi-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { checkPrivacy } from '@/mixins'
import { MiTitle, MiButton } from '@/components'

export default {
  mixins: [checkPrivacy],
  components: {
    MiTitle,
    MiButton
  },
  computed: {
    ...mapState({
      userInfo: state => state.about.userInfo
    }),
    showCard() {
      return this.userInfo.giftcardAvailableNumber > 0
    }
  },
  async created() {
    await this.$store.dispatch('getHomeInfo')
    this.checkPrivacy()
  }
}
</script>

<style lang="scss" scoped>
.about {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .icon-desc {
    @extend .mi-flex-center;
    font-size: 38px;
    color: $color-text-secondary;
    flex-shrink: 0;
    margin-left: 40px;
    .icon {
      width: 42px;
      height: 42px;
      @include bg-image('mi');
      margin-right: 5px;
    }
  }
  &-amount {
    padding: 50px 36px 3px;
    &-main {
      width: 100%;
      height: 435px;
      @include bg-image('card');
      color: $white-color;
      padding: 60px;
      .title {
        font-size: 36px;
      }
      .amount {
        font-size: 96px;
        padding: 5px 0 25px;
        font-family: Mitype;
        .contain {
          font-style: normal;
          font-weight: normal;
          font-size: 36px;
        }
      }
      .recharge {
        width: 282px;
        height: 90px;
        background: #fc9560;
        @include round(90px);
        line-height: 90px;
        text-align: center;
        color: $white-color;
      }
    }
  }
  &-list {
    padding: 0 80px;
    li {
      display: flex;
      a {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 94px;
        font-weight: 500;
        font-size: 50px;
        color: $theme-text-color;
      }
      .right {
        color: $color-text-warn;
        font-size: 42px;
        font-style: normal;
        font-weight: normal;
        display: flex;
        align-items: center;
      }
      .icon {
        width: 26px;
        height: 42px;
        @include level-bg-image('arrow');
        margin-left: 24px;
      }
    }
  }
  .footer {
    padding: 0 77px 90px;
  }
}
</style>
