<template>
  <transition name="fade">
    <div v-show="visible" class="mi-message" @click.self="whetherClose">
      <transition name="slide">
        <div v-if="visible" class="picker">
          <h2 class="title">{{ title }}</h2>
          <p class="content" v-html="content"></p>
          <div class="btn-main">
            <span class="cancel" v-if="type === 'two'" @click="close">{{
              cancel
            }}</span>
            <span class="confirm" @click="confirmHanler">{{ confirm }}</span>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      title: '',
      content: '',
      cancel: '取消',
      confirm: '确定',
      type: 'two',
      modalClose: true
    }
  },
  methods: {
    whetherClose() {
      if (this.modalClose) {
        this.close()
      }
    },
    close() {
      this.visible = false
      this.promiseHandler?.('cancel')
    },
    confirmHanler() {
      this.visible = false
      this.promiseHandler?.('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.mi-message {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 110;
  .picker {
    padding: 80px;
    width: 100%;
    background-color: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 101;
    border-radius: 66px 66px 0 0;
    text-align: center;
    .title {
      font-weight: 500;
      font-size: 50px;
      color: $theme-text-color;
    }
    .content {
      font-size: 48px;
      color: $title-color-text;
      padding: 50px 0 80px;
      /deep/ a {
        color: #0d84ff;
      }
    }
    .btn-main {
      display: flex;
      justify-content: space-between;
      span {
        flex: 1;
        height: 140px;
        @include round(140px);
        text-align: center;
        line-height: 140px;
        font-weight: 500;
        font-size: 48px;
      }
      .cancel {
        margin-right: 40px;
        background: rgba(0, 0, 0, 0.06);
        color: $title-color-text;
      }
      .confirm {
        color: $white-color;
        background: $theme-color;
      }
    }
  }
}

.slide-enter-active {
  transition: transform 0.4s;
}
.slide-leave-active {
  transition: transform 0.3s;
}
.slide-enter,
.slide-leave-to {
  transform: translateY(100%);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
