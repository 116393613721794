/**
 * 支付方式名称
 * @author cuiliangliang
 */

export default {
  'zh-CN': {
    wxpay: '微信支付',
    alipay: '支付宝',
    mipay: '小米支付',
    paypal: 'PayPal',
    phonecard: '话费充值卡',
    voucher: '米币卡'
  },
  'zh-HK': {
    wxpay: '微信',
    alipay: '支付寶',
    mipay: '小米支付',
    paypal: 'PayPal',
    voucher: '米幣卡'
  },
  'en-US': {
    wxpay: 'WeChat',
    alipay: 'Alipay',
    mipay: 'Mi Wallet',
    paypal: 'PayPal',
    voucher: 'Mi Coins card'
  }
}
