export default [
  {
    path: '/payment',
    name: 'payment',
    component: () =>
      import(/* webpackChunkName: "payment" */ '@/views/payment/index.vue')
  },
  {
    path: '/payment-result',
    name: 'paymentResult',
    component: () =>
      import(
        /* webpackChunkName: "paymentResult" */ '@/views/payment/result.vue'
      )
  },
  {
    path: '/paypalReturn',
    name: 'paypalReturn',
    component: () =>
      import(
        /* webpackChunkName: "paypalReturn" */ '@/views/payment/paypal.vue'
      )
  }
]
