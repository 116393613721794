<template>
  <div class="mi-button" :class="{ disabled }" @click.stop="clickHandle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    clickHandle() {
      if (this.disabled) return
      this.$emit('click')
    }
  }
}
</script>
