import { ABOUT_GET_USER_INFO } from '../mutations'
import { unitFormat } from '@/utils'
import http from '@/api'

const userId = sessionStorage.getItem('mibi-userId')

export default {
  state: {
    userInfo: { userId, nickName: '' }
  },
  mutations: {
    [ABOUT_GET_USER_INFO](state, payload) {
      sessionStorage.setItem('mibi-userId', payload.userId)
      payload.balance = unitFormat(payload.balance)
      payload.giftcardAvailableValue = unitFormat(
        payload.giftcardAvailableValue
      )
      state.userInfo = payload
    }
  },
  actions: {
    async getHomeInfo({ commit }) {
      const res = await http.homeInfo()

      if (res) {
        commit(ABOUT_GET_USER_INFO, res)
      }
    }
  }
}
