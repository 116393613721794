import toast from './toast.vue'

export default function(Vue) {
  const ToastConstructor = Vue.extend(toast)
  const instance = new ToastConstructor()

  document.body.appendChild(instance.$mount().$el)

  const func = (message, duration = 1500) => {
    Object.assign(instance, { message, duration, visible: true })
    setTimeout(() => {
      instance.visible = false
    }, duration)
  }

  Vue.prototype.$toast = Vue.toast = window.$$toast = func
}
