/**
 * @field api
 * @author cuiliangliang
 */

import apiList from './api-list'

export const http = {
  install(Vue) {
    Vue.prototype.$http = apiList
  }
}

export default apiList
