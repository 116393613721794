import Vue from 'vue'
import VueI18n from 'vue-i18n'

import zhCN from './lang/zh-CN'
import zhHK from './lang/zh-HK'
import enUS from './lang/en-US'

Vue.use(VueI18n)

let locale = 'zh-CN'
const messages = {
  'zh-CN': zhCN,
  'zh-HK': zhHK,
  'en-US': enUS
}

const localKey = sessionStorage.getItem('localKey')

if (localKey) {
  locale = localKey
}

export default new VueI18n({
  locale,
  messages
})
