export default {
  login: {
    desc: '輕鬆安心加值 樂享小米服務',
    btnText: '去登入',
    register: '現在註冊',
    password: '忘記密碼',
    footer: '小米旗下米幣加值服務平台'
  },
  about: {
    recharge: '加值',
    balance: '目前餘額（米幣）',
    rechargeRecord: '加值記錄',
    consumeRecord: '消費記錄',
    gift: '我的禮券',
    btnText: '立即加值'
  },
  record: {
    customeTitle: '消費記錄',
    rechargeTitle: '儲值記錄',
    amount: '餘額：{count}米幣',
    alipay: '支付寶儲值',
    wechat: '微信支付儲值',
    miPay: '小米錢包儲值',
    mibi: '米幣卡儲值',
    phone: '話費儲值卡',
    footer: '- 沒有更多了 -'
  },
  gift: {
    title: '我的禮券',
    usable: '可用',
    invalid: '失效',
    used: '已用',
    badgeUsable: '可使用',
    badageInvalid: '已失效',
    badageUsed: '可使用',
    term: '有效期至',
    desc: '滿{count}米幣可用',
    overflow: '可分多次使用',
    give: '送{count}米幣'
  },
  recharge: {
    title: '米幣加值',
    payType: '選擇加值方式',
    otherPay: '更多加值方式',
    phone: '話費加值卡',
    mibi: '米幣卡',
    amount: '選擇米幣金額',
    otherAmount: '其他金額',
    palceholder: '請輸入金額',
    mibiTitle: '米幣卡加值',
    mibiPwd: '米幣卡密碼',
    mibiPlaceholder: '請輸入米幣卡密碼',
    operator: '選擇營運商',
    mibiAmount: '選擇米幣金額',
    mobile: '移動',
    unicom: '聯通',
    telecom: '電信',
    phoneTitle: '加值卡資訊',
    cardPlaceholder: '請輸入卡號',
    pwdPlaceholder: '請輸入密碼',
    desc: [
      '全部為一次性加值，不支援分次；',
      '所選面值必須於加值卡實際面值保持一致，選擇錯誤講導致資金損失；',
      '請您正確填寫卡號、密碼資訊，若與實際資訊不符導致的損失請用戶自行承擔，請確認無誤後再操作加值；'
    ],
    picker: {
      title: '溫馨提醒',
      desc:
        '卡號、面值、密碼與實際資訊不符導致的損失請您自行承擔，請確認無誤後操作加值'
    },
    warn: '僅支持30以上的加值金額',
    overAmount: '每次最多加值10000米幣',
    overPoint: '加值金額為整數或最多兩位小數',
    integer: '必須為整數'
  },
  rechargeResult: {
    success: '{count}米幣儲值成功',
    anount: '米幣餘額：{count}米幣',
    failed: '您的加值未成功'
  },
  payment: {
    btn: '還需支付{count}米幣',
    confirm: '確認支付',
    picker: {
      desc: '確認放棄支付嗎？',
      cancel: '繼續支付',
      confirm: '確認離開'
    },
    priorityUse: '優先使用',
    unit: '（1米幣=1元）',
    warn: '請保證實際支付金額大於30元'
  },
  paymentResult: {
    success: '成功支付{count}米幣',
    failed: '您的支付未成功',
    goMibi: '前往米幣中心',
    jump: '3秒後自動跳轉'
  },
  docTitle: {
    payment: '米幣收銀台',
    aboutTitle: '米幣中心'
  },
  common: {
    total: '共{count}米幣',
    amount: '米幣餘額：{count}米幣',
    noRecord: '暫無記錄',
    mibi: '米幣',
    balance: '餘額',
    rechargeBtn: '立即加值',
    phoneCard: '話費加值卡',
    confirm: '確定',
    cancel: '取消',
    done: '完成',
    retry: '重試',
    back: '返回',
    yuan: '元',
    wechat: '微信支付',
    alipay: '支付寶',
    miPay: '小米錢包',
    mibiAmount: '米幣餘額',
    accuout: '{count}米幣',
    unit: '米幣',
    notData: '暫無記錄'
  },
  message: {
    warn: '請選擇支付方式',
    checkPrivacy: {
      content:
        '為了實現本服務的基本功能和風險控制，您同意小米收集、處理和使用您的個人信息，包括小米賬號的相關信息，當前選擇的語言和國家，IP地址以及用戶代理信息。隱私政策中說明了我們會如何處理您的信息。選擇下方的“我同意”表示您同意小米的<a href="/license?userId={count}" target="_blank">《用戶協議》</a><a href="http://www.miui.com/res/doc/privacy/cn.html" target="_blank">《隱私政策》</a>。',
      confirm: '我同意',
      cancel: '不同意'
    },
    notService: {
      content: '您已申請撤銷隱私協議授權，米幣業務無法提供服務。',
      confirm: '我知道了'
    }
  },
  error: {
    title: '出錯了',
    desc: '我們正在努力解決'
  },
  payName: {
    voucher: '米幣卡'
  },
  paypal: {
    warn: '如已支付成功，請點擊"已完成付款"',
    completed: '已完成付款',
    desc: '請稍後再查看訂單狀態',
    tips: '狀態異常。若您已完成訂單，請不要重複點擊。',
    wait: '查詢訂單中，請您耐心等待查詢結果',
    retry: '您的款項將不被扣除，請重試'
  }
}
