/**
 * @file api-list
 * @author cuiliangliang
 */

/* eslint-disable */

import { get, post } from './options'
import syncHttp from './sync'

export default {
  homeInfo          :     params => get('/mibiHomeInfo', params),                         // 首页信息
  getGiftCard       :     params => get('/record/giftcardQueryV2', params),               // 礼券列表
  getTrade          :     params => get('/record/tradeQueryV2', params),                  // 消费记录
  getRecharge       :     params => get('/record/rechargeQueryV2', params),               // 充值记录
  getChargeType     :     params => get('/chargeTypeInfo', params),                       // 获取充值方式
  mibiPayInfo       :     data   => post('/mibiPayInfo', data),                           // 获取收银台信息
  wxpayRecharge     :     data   => syncHttp('/recharge/wxpayPost', data),                // 微信充值
  alipayRecharge    :     data   => syncHttp('/recharge/alipayPost', data),               // 支付宝充值
  mipayRecharge     :     data   => syncHttp('/recharge/mipayPost', data),                // 小米充值
  paypalRecharge    :     data   => syncHttp('/recharge/paypalCharge', data),             // 海外充值
  phoneRecharge     :     data   => syncHttp('/recharge/phonecard', data),                // 手机卡充值
  voucherRecharge   :     data   => syncHttp('/recharge/codeCharge', data),               // 米币卡充值
  mibiPay           :     data   => syncHttp('/mibiPay', data),                           // 米币收银台支付
  payResult         :     params => get('/queryChargePayResult', params),                 // 查询支付结果（有账号）
  noPayResult       :     params => get('/na/queryOrderResult', params),                  // 查询支付结果（无账号）
  checkPrivacy      :     params => get('/checkPrivacy', params),                         // 检查用户隐私协议同意状态 （海外服务）
  privacyAuthorize  :     data   => post('/privacy/authorize', data),                     // 用户同意/不同意隐私授权（海外服务）
  paypalCapture     :     data   => post('/paypalImmediateCapture', data),                // 获取PayPal支付结果
  payResultV2       :     params => get('/queryChargePayResultV2', params),                 // 查询支付结果（billcenter-wap-hk服务,免登录）
}
