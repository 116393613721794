/**
 * 运营商
 * @author cuiliangliang
 */

export default {
  cmcc: '移动',
  telcom: '电信',
  unicom: '联通'
}
