export default {
  login: {
    desc: '轻松安心充值 乐享小米服务',
    btnText: '去登录',
    register: '现在注册',
    password: '忘记密码',
    footer: '小米旗下米币充值服务平台'
  },
  about: {
    recharge: '充值',
    balance: '当前余额（米币）',
    rechargeRecord: '充值记录',
    consumeRecord: '消费记录',
    gift: '我的礼券',
    btnText: '立即充值'
  },
  record: {
    customeTitle: '消费记录',
    rechargeTitle: '充值记录',
    amount: '余额：{count}米币',
    alipay: '支付宝充值',
    wechat: '微信支付充值',
    miPay: '小米钱包充值',
    mibi: '米币卡充值',
    phone: '话费充值卡',
    footer: '- 没有更多了 -'
  },
  gift: {
    title: '我的礼券',
    usable: '可用',
    invalid: '失效',
    used: '已用',
    badgeUsable: '可使用',
    badageInvalid: '已失效',
    badageUsed: '可使用',
    term: '有效期至',
    overflow: '满{count}米币可用',
    desc: '可分多次使用',
    give: '送{count}米币'
  },
  recharge: {
    title: '米币充值',
    payType: '选择充值方式',
    otherPay: '更多充值方式',
    phone: '话费充值卡',
    mibi: '米币卡',
    amount: '选择米币金额',
    otherAmount: '其他金额',
    palceholder: '请输入金额',
    mibiTitle: '米币卡充值',
    mibiPwd: '米币卡密码',
    mibiPlaceholder: '请输入米币卡密码',
    operator: '选择运营商',
    mibiAmount: '选择米币金额',
    mobile: '移动',
    unicom: '联通',
    telecom: '电信',
    phoneTitle: '充值卡信息',
    cardPlaceholder: '请输入卡号',
    pwdPlaceholder: '请输入密码',
    desc: [
      '全部为一次性充值，不支持分次；',
      '所选面值必须于充值卡实际面值保持一致，选择错误将导致资金损失；',
      '请您正确填写卡号、密码信息，若与实际信息不符导致的损失请用户自行承担，请确认无误后再操作充值；'
    ],
    picker: {
      title: '温馨提示',
      desc:
        '卡号、面值、密码与实际信息不符导致的损失请您自行承担，请确认无误后操作充值'
    },
    warn: '仅支持30以上的充值金额',
    overAmount: '每次最多充值10000米币',
    overPoint: '充值金额为整数或最多两位小数',
    integer: '必须为整数'
  },
  rechargeResult: {
    success: '{count}米币充值成功',
    anount: '米币余额：{count}米币',
    failed: '您的充值未成功',
    waitTitle: '充值请求已提交',
    waitDesc: '去充值记录查询详情'
  },
  payment: {
    btn: '还需支付{count}米币',
    confirm: '确认支付',
    picker: {
      desc: '确认放弃支付吗？',
      cancel: '继续支付',
      confirm: '确认离开'
    },
    priorityUse: '优先使用',
    unit: '（1米币=1元）',
    warn: '请保证实际支付金额大于30元'
  },
  paymentResult: {
    success: '成功支付{count}米币',
    failed: '您的支付未成功',
    goMibi: '前往米币中心',
    jump: '3秒后自动跳转'
  },
  docTitle: {
    payment: '米币收银台',
    aboutTitle: '米币中心'
  },
  common: {
    total: '共{count}米币',
    amount: '米币余额：{count}米币',
    noRecord: '暂无记录',
    mibi: '米币',
    balance: '余额',
    rechargeBtn: '立即充值',
    phoneCard: '话费充值卡',
    confirm: '确定',
    cancel: '取消',
    done: '完成',
    retry: '重试',
    back: '返回',
    yuan: '元',
    wechat: '微信支付',
    alipay: '支付宝',
    miPay: '小米钱包',
    mibiAmount: '米币余额',
    accuout: '{count}米币',
    unit: '米币',
    notData: '暂无记录'
  },
  message: {
    warn: '请选择支付方式',
    checkPrivacy: {
      content:
        '为了实现本服务的基本功能和风险控制，您同意小米收集、处理和使用您的个人信息，包括小米账号的相关信息，当前选择的语言和国家，IP地址以及用户代理信息。隐私政策中说明了我们会如何处理您的信息。选择下方的“我同意”表示您同意小米的<a href="/license?userId={count}" target="_blank">《用户协议》</a><a href="http://www.miui.com/res/doc/privacy/cn.html" target="_blank">《隐私政策》</a>。',
      confirm: '我同意',
      cancel: '不同意'
    },
    notService: {
      content: '您已申请撤销隐私协议授权，米币服务无法提供服务。',
      confirm: '我知道了'
    }
  },
  error: {
    title: '出错了',
    desc: '我们正在努力解决'
  },
  payName: {
    voucher: '米币卡'
  },
  paypal: {
    warn: '如已支付成功，请点击"已完成付款"',
    completed: '已完成付款',
    desc: '请稍后再查看订单状态',
    tips: '状态异常。若您已完成订单，请不要重复点击。',
    wait: '查询订单中，请您耐心等待查询结果。',
    retry: '您的款项将不被扣除，请重试'
  }
}
