export default {
  login: {
    desc: 'Pay for all Xiaomi services in one place',
    btnText: 'Sign in',
    register: 'Create account',
    password: 'Forgot password',
    footer: 'Mi Coins by Xiaomi'
  },
  about: {
    recharge: 'Top up',
    balance: 'Balance (Mi Coins)',
    rechargeRecord: 'Top-up history',
    consumeRecord: 'Purchase history',
    gift: 'My gift cards',
    btnText: 'Top up'
  },
  record: {
    customeTitle: 'Purchase history',
    rechargeTitle: 'Top-up history',
    amount: 'Balance: {count} Mi Coin | Balance: {count} Mi Coins',
    alipay: 'Alipay',
    wechat: 'WeChat Pay',
    miPay: 'Mi Wallet',
    mibi: 'Mi Coins card',
    phone: 'Phone top-up card',
    footer: '- No more -'
  },
  gift: {
    title: 'My gift cards',
    usable: 'Available',
    invalid: 'Expired',
    used: 'Redeemed',
    badgeUsable: 'Available',
    badageInvalid: 'Expired',
    badageUsed: 'Available',
    term: 'Valid until ',
    desc: 'can be split',
    overflow:
      'Valid for purchases above {count} Mi Coin | Valid for purchases above {count} Mi Coins',
    give: '{count} bonus coin | {count} bonus coins}'
  },
  recharge: {
    title: 'Top up',
    payType: 'Select top-up method',
    otherPay: 'More top-up methods',
    phone: 'Phone top-up card',
    mibi: 'Mi Coins card',
    amount: 'Select amount',
    otherAmount: 'Other',
    palceholder: 'Enter amount',
    mibiTitle: 'Mi Coins card',
    mibiPwd: 'Mi Coins card password',
    mibiPlaceholder: 'Enter Mi Coins card password',
    operator: 'Select carrier',
    mibiAmount: 'Select amount',
    mobile: 'China Mobile',
    unicom: 'China Unicom',
    telecom: 'China Telecom',
    phoneTitle: 'Top-up card info',
    cardPlaceholder: 'Enter card number',
    pwdPlaceholder: 'Enter password',
    desc: [
      "One transaction can't be split.",
      'Selected nominal value must be consistent with that of the prepaid card. Choosing another value will void the card.',
      'Enter your card number, face value, and password. Any loss caused by the discrepancy between the information you provide and the actual information shall be borne by you.'
    ],
    picker: {
      title: 'Attention',
      desc:
        'Any loss caused by the discrepancy between the card number, face value, password and the actual information shall be borne by you. Double check the information you provide before you proceed.'
    },
    warn: 'Only support more than 30 amount of recharge',
    overAmount: 'Top up 10000 Mi Coins',
    overPoint:
      'The recharge amount is an integer or two decimal places at most',
    integer: 'Must be an integer'
  },
  rechargeResult: {
    success:
      'Purchased {count} Mi Coin successfully | Purchased {count} Mi Coins successfully',
    anount: 'Balance: {count} Mi Coin | Balance: {count} Mi Coins',
    failed: "Couldn't top up"
  },
  payment: {
    btn: 'Add {count} Mi Coin to pay | Add {count} Mi Coins to pay',
    confirm: 'Pay',
    picker: {
      desc: 'Discard this payment?',
      cancel: 'Continue',
      confirm: 'Exit'
    },
    priorityUse: 'Use first',
    unit: '(1 Mi Coin = 1 CNY)',
    warn: 'Please ensure that the actual payment amount is greater than 30 yuan'
  },
  paymentResult: {
    success: 'Paid with {count} Mi Coin | Paid with {count} Mi Coins',
    failed: "our payment wasn't completed",
    goMibi: 'Open Mi Coins',
    jump: 'Leaving in 3 seconds…'
  },
  docTitle: {
    payment: 'Mi Coins payment',
    aboutTitle: 'Mi Coins'
  },
  common: {
    total: '{count} Mi Coin total | {count} Mi Coins total',
    amount: 'Balance: {count} Mi Coin | Balance: {count} Mi Coins',
    noRecord: 'No items here yet',
    mibi: 'Mi Coins',
    balance: 'Balance',
    rechargeBtn: 'Top up',
    phoneCard: 'Phone top-up card',
    confirm: 'OK',
    cancel: 'Cancel',
    done: 'Done',
    retry: 'Try again',
    back: 'Back',
    yuan: ' CNY',
    wechat: 'WeChat Pay',
    alipay: 'Alipay',
    miPay: 'Mi Wallet',
    mibiAmount: 'Balance',
    accuout: '{count} Mi Coin | {count} Mi Coins',
    unit: 'Mi Coin | Mi Coins',
    notData: 'No items here yet'
  },
  message: {
    warn: 'Select payment method',
    checkPrivacy: {
      content: ` In order to realize the basic functions and risk control of this service,
                 you agree that Xiaomi collects, processes and uses your personal information, 
                 including information related to Xiaomi's account number, currently selected language and country, 
                 IP address and user agent information. 
                 The privacy policy explains how we treat your information. By selecting "I agree" below, 
                 you agree to Xiaomi's <a href="http://www.miui.com/res/doc/privacy/en.html" target="_blank">Privacy Policy</a> and <a href="/license" target="_blank">User Agreement.</a>`,
      confirm: 'I agree',
      cancel: 'No'
    },
    notService: {
      content: `You can't use Mi Coins after authorization has been revoked.`,
      confirm: 'Ok'
    }
  },
  error: {
    title: 'An error occurred',
    desc: "We're fixing it now."
  },
  payName: {
    voucher: 'Mi Coins card'
  },
  paypal: {
    warn: 'If the payment is successful, please click "Payment completed"',
    completed: 'Payment completed',
    desc: 'Please check your order status later',
    tips:
      'Abnormal status. If you have already completed the order, please do not click again.',
    wait: 'In the query order, please wait patiently for the query result',
    retry: 'Your payment will not be debited, please try again'
  }
}
