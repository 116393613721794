/**
 * zh-CN
 * @author cuiliangliang
 */

const zhCN = () => {
  const { host } = window.location
  const hostArr = ['m.staging.mibi.n.xiaomi.com', 'm.mibi.mi.com']

  return hostArr.includes(host)
}

export default zhCN()
