/**
 * 语言环境
 * @author cuiliangliang
 */

import zhCN from './zh_CN'

const getLocale = () => {
  let locale = 'zh-CN'

  if (!zhCN) {
    const { language } = navigator

    if (language !== 'zh-CN' && language.startsWith('zh')) {
      locale = 'zh-HK'
    } else if (language.startsWith('en')) {
      locale = 'en-US'
    }
  }

  return locale
}

export default getLocale()
