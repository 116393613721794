import About from '@/views/about/index.vue'

export default [
  {
    path: '/',
    name: 'about',
    component: About
  },
  {
    path: '/record/giftcard',
    name: 'giftRecord',
    component: () =>
      import(/* webpackChunkName: "giftRecord" */ '@/views/about/gift.vue')
  },
  // 兼容接口
  {
    path: '/record/all',
    redirect: '/record/recharge'
  },
  {
    path: '/record',
    redirect: '/record/consume'
  },
  {
    path: '/record/trade',
    redirect: '/record/consume'
  },
  {
    path: '/record/:type',
    name: 'record',
    component: () =>
      import(/* webpackChunkName: "consumeRecord" */ '@/views/about/record.vue')
  }
]
