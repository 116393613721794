/**
 * 同步请求
 * @author cuiliangliang
 */

import cookie from 'js-cookie'

const serviceToken = cookie.get('serviceToken')

export default (url, data) => {
  data.serviceToken = serviceToken

  const form = document.createElement('form')
  const fragment = document.createDocumentFragment()

  form.action = url
  form.method = 'post'
  form.style.display = 'none'

  Object.entries(data).forEach(([name, value]) => {
    const input = document.createElement('input')

    input.name = name
    input.value = value
    fragment.appendChild(input)
  })

  form.appendChild(fragment)
  document.body.appendChild(form)
  form.submit()
}
