import MiDicator from './indicator'

export default function(Vue) {
  const dicatorConstructor = Vue.extend(MiDicator)
  const instance = new dicatorConstructor()

  document.body.appendChild(instance.$mount().$el)

  const indicator = {
    show() {
      instance.visible = true
    },
    hide() {
      instance.visible = false
    }
  }

  Vue.prototype.$indicator = Vue.$indicator = window.$indicator = indicator
}
