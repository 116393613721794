/**
 * @description
 * mutation 命名必须全局唯一且大写
 * 命名规范：
 * 模块 + 页面 + 属性 + 动作（下划线连接）
 * 比如：
 * ABOUT_GIT_LIST_GET
 */

/************ about *******************/
export const ABOUT_GET_USER_INFO = 'ABOUT_GET_USER_INFO '

/************ recharge ****************/
export const RECHARGE_GTE_CHARGE_TYPE_INFO = 'RECHARGE_GTE_CHARGE_TYPE_INFO'
export const RECHARGE_GET_AMOUNT_LIST = 'RECHARGE_GET_AMOUNT_LIST'
export const RECHARGE_UPDATE_AMOUNT_LIST = 'RECHARGE_UPDATE_AMOUNT_LIST'
export const RECHARGE_GET_PHONE_INFO = 'RECHARGE_GET_PHONE_INFO'
export const RECHARGE_UPDATE_ID = 'RECHARGE_UPDATE_ID'
